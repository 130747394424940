<template>
  <div class="relative">
    <BlogNavigation
      v-if="data?.wp_post_type?.includes('toc-card')"
      :currentSectionIndex="currentSectionIndex"
      :fullViewToC="fullViewToC"
      :dataBlocks="data.blocks"
      @update:fullViewToC="(newVal) => (fullViewToC = newVal)"
      @update:currentSectionIndex="
        (newInd) => {
          scrollToSection(newInd);
        }
      "
    />
    <div
      v-if="isNotProd"
      class="w-1/2 rounded-full font-bold fixed py-2 bottom-5 text-center z-20 left-1/2 -translate-x-1/2"
      :class="data.isDraft ? 'bg-yellow-500' : 'bg-green-500'"
    >
      {{ data.isDraft ? 'Page is in Draft' : 'Page is LIVE' }}
    </div>
    <div v-if="data" class="max-w-screen-md mx-auto">
      <ReaderZone>
        <h1 v-if="data.title" class="pt-5 pb-2 text-xl" v-html="data.title"></h1>
        <div class="flex flex-col justify-between authors-container">
          <div class="flex items-end flex-wrap justify-between py-4">
            <div v-for="author in sortedAuthors" :key="author.name" class="font-semibold flex items-start space-x-4">
              <div class="w-14 h-14 flex-shrink-0">
                <SmartImg
                  v-if="author.image"
                  class="object-cover rounded bg-gray-300 dark:bg-gray-600 w-full h-full"
                  :src="author.image"
                  :alt="`${author.name} profile image`"
                  sizes="20px"
                />
                <div v-else class="w-full h-full rounded bg-transparent"></div>
              </div>
              <div class="flex flex-col justify-center">
                <NuxtLinkLocale v-if="author.url" :to="author.url" class="">{{ author.name }}</NuxtLinkLocale>
                <NuxtLinkLocale v-else to="/about-us" class="text-gray-600 dark:text-gray-300">{{
                  author.name
                }}</NuxtLinkLocale>
                <span>{{ author.role }}</span>
                <span class="text-gray-600 dark:text-gray-300 text-sm font-medium">
                  {{ $t('Updated on: {date}', { date: localizedDate(new Date(data.wp_last_updated), 'long') }) }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <SmartImg
            v-show="data.featured_image && !featuredImageLoaded"
            src="/img/placeholder.png"
            class="absolute top-0 left-0 w-full rounded-lg td-post-featured-image"
            sizes="(max-width: 767px) 100vw, 768px"
          />
          <SmartImg
            v-if="data.featured_image"
            :src="data.featured_image"
            @imgLoad="featuredImageLoaded = true"
            class="w-full rounded-lg td-post-featured-image transition-opacity duration-300 ease-in-out"
            :class="{ 'opacity-0': !featuredImageLoaded, 'opacity-100': featuredImageLoaded }"
            sizes="(max-width: 767px) 100vw, 768px"
          />
        </div>
      </ReaderZone>
      <div v-for="block in data.blocks" :key="block.id" class="order-1">
        <ReaderZone v-if="block.__component == 'block.html'">
          <div class="py-5 td-post-content">
            <div v-if="data?.wp_post_type?.includes('toc-card') || false" class="content">
              <div v-for="(section, ind) in getSections(block.content)" :key="ind" :sectionIndex="section.sectionIndex">
                <!-- Render the header -->
                <div v-if="section.header" v-html="section.header"></div>
                <!-- Render paragraphs -->
                <div v-for="(paragraph, index) in section.paragraphs" :key="index">
                  <div
                    v-processsmartimg
                    :class="{ hidden: paragraph.hidden && isCollapsed(section.sectionIndex) }"
                    v-html="paragraph.content"
                  ></div>
                </div>
                <!-- Read More button -->
                <a
                  v-if="section.hasHiddenContent && isCollapsed(section.sectionIndex)"
                  @click="toggleReadMore(section.sectionIndex)"
                  class="flex items-center"
                >
                  <div class="flex items-center">
                    <span class="leading-none block">Read More</span>
                    <img
                      src="/img/icons/chevron_right.svg"
                      class="w-8 h-8 icon dark:filter dark:invert dark:invert-1 rotate-90 object-contain"
                    />
                  </div>
                </a>
                <!-- Render other content -->
                <div v-for="(other, index) in section.otherContent" :key="index" v-html="other.content"></div>
                <MobileCarousel
                  v-if="section.sectionIndex == 0 && isMobile && data?.wp_post_type?.includes('toc-card')"
                  :currentSectionIndex="currentSectionIndex"
                  :dataBlocks="data.blocks"
                  :fullViewToC="fullViewToC"
                  @update:fullViewToC="(newVal) => (fullViewToC = newVal)"
                  @update:currentSectionIndex="
                    (newInd) => {
                      scrollToSection(newInd);
                    }
                  "
                />
              </div>
            </div>
            <div v-else v-html="block.content"></div>
          </div>
        </ReaderZone>
        <!-- Other block types -->
        <div v-else-if="block.cta" class="py-5">
          <button class="ts-button" @click="openButtonLink(block.cta.link)">{{ block.cta.title }}</button>
        </div>
        <div v-else-if="block.__component == 'block.blocks'">
          <div v-if="block.view_type == 'carousel'">
            <Carousel
              :itemType="'activity'"
              class="my-10 md:my-2 md:py-8 mb-5 md:mb-0"
              :searchParams="{ text_search: block.query }"
              :URL="'/search?q=' + block.query"
              :buttonLabel="t('View All')"
            />
          </div>
        </div>
        <div v-else-if="block.__component == 'block.button'" class="py-5 justify-center text-center">
          <button class="ts-button" @click="openButtonLink(block.link)">{{ block.title }}</button>
        </div>
      </div>
      <section class="order-0">
        <div id="post_authors-title" class="font-semibold">
          {{ sortedAuthors.length > 1 ? $t('Authors') : $t('Author') }}
        </div>
        <div class="flex items-center flex-wrap">
          <div v-for="author in sortedAuthors" :key="author.name" class="font-semibold flex items-center space-x-4">
            <div class="w-14 h-14 flex-shrink-0">
              <SmartImg
                v-if="author.image"
                class="object-cover rounded bg-gray-300 dark:bg-gray-600 w-full h-full"
                :src="author.image"
                :alt="`${author.name} profile image`"
                sizes="20px"
              />
              <div v-else class="w-full h-full rounded bg-transparent"></div>
            </div>
            <div class="flex flex-col justify-center">
              <NuxtLinkLocale v-if="author.url" :to="author.url" class="">{{ author.name }}</NuxtLinkLocale>
              <NuxtLinkLocale v-else to="/about-us" class="text-gray-600 dark:text-gray-300">{{
                author.name
              }}</NuxtLinkLocale>
              <span>{{ author.role }}</span>
              <span class="text-gray-600 dark:text-gray-300 text-sm font-medium">
                {{ $t('Updated on: {date}', { date: localizedDate(new Date(data.wp_last_updated), 'long') }) }}</span
              >
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { createError, useHead, useRoute, useSeoMeta } from '#imports';
import { computed, createApp, defineEmits, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MobileCarousel from '~/components/BlogNavigation/MobileCarousel.vue';
import { handleApiResponse } from '~/server/utils/utils';

const runtimeConfig = useRuntimeConfig();
const { t, locale } = useI18n();
const route = useRoute();
const isNotProd = ref(runtimeConfig.public.env !== 'production');
const { isMobile } = useDevice();

// Note: sectionIndexes start from 1, 0 here because the intro is displayed first
const currentSectionIndex = ref(0);
const fullViewToC = ref(false);
const featuredImageLoaded = ref(false);

const emits = defineEmits(['passDataToHeader']);

function openButtonLink(link) {
  window.location.href = link;
}

// Fetch data
const { data: responseData } = await handleApiResponse(`/api/CMS/page`, {
  params: {
    lang: locale.value.split('-')[0],
    path: route.path,
  },
});

// Check if data exists
if (!responseData || !responseData.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page not found' });
} else if (responseData?.value?.locations?.data[0]?.attributes?.name) {
  try {
    // Combing through the locations and getting the object with the type of city
    const cityName = Object.values(responseData.value.locations.data).find(
      (location) => location.attributes.type === 'city',
    ).attributes.name;

    // Usually the ID are correct but some return old ID's, so keeping this here
    const response = await $fetch('/api/destinations', {
      method: 'GET',
      params: {
        city_name: cityName,
        lang: locale.value,
      },
    });

    // Passing the id to the header. The header fetches nav menu items using this ID
    emits('passDataToHeader', { cityID: response.destination_id });
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

// Use computed to extract the data
const data = computed(() => responseData.value);

const sortedAuthors = computed(() => {
  let authors = [];

  if (!data.value.author) return [];
  if (!data.value.author.data) return [];

  if (!Array.isArray(data.value.author)) authors = [data.value.author.data.attributes];
  else if (data.value.author.length === 1) authors = [data.value.author[0]];
  else authors = [...data.value.author]
    .sort((a, b) => {
      if (a.data.attributes.url && !b.data.attributes.url) return -1;
      if (!a.data.attributes.url && b.data.attributes.url) return 1;
      return 0;
    })
    .map((author) => author.data.attributes);

  authors.forEach(author => {
    if (author.url.includes('content-team')) {
      author.url = author.url.replace('/tourscanner-content-team', '');
    }
  });

  return authors;
});

// Set page head and SEO metadata
useHead({
  title: data.value.title,
  ogImage: data.value.featured_image,
  description: data.value.description,
  ogDescription: data.value.description,
  meta: [
    { name: 'title', content: data.value.title },
    {
      name: 'robots',
      content: data.value.index
        ? 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        : 'noindex, nofollow',
    },
    { name: 'description', content: data.value.description },
    { name: 'keywords', content: data.value.keys ?? '' },
  ],
});

const rule = useRobotsRule();
rule.value = data.value.index
  ? 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
  : 'noindex, follow';

useSeoMeta({
  title: data.value.title,
  ogTitle: data.value.title,
  ogImage: data.value.featured_image,
});

onMounted(() => {
  window.addEventListener('scroll', updateCurrentSectionIndex);
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateCurrentSectionIndex);
});

// Collapsed state for each section
const collapsedSections = ref({});

// Check if a section is collapsed
const isCollapsed = (sectionIndex) => {
  return collapsedSections.value[sectionIndex] !== false;
};

// Toggle collapsed state
const toggleReadMore = (sectionIndex) => {
  collapsedSections.value[sectionIndex] = false;
};

// Function to get text length from HTML string
function getTextLength(html) {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent.length;
}

// Function to split content into sections based on <h2> and <h3> headers
const getSections = (content) => {
  if (process.server) return [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const elements = Array.from(doc.body.childNodes);

  const sections = [];
  let currentSection = { header: null, paragraphs: [], otherContent: [], sectionIndex: 0 };
  let sectionIndex = 1;

  elements.forEach((node, index) => {
    const tagName = node.tagName?.toLowerCase();
    if (tagName === 'h2' || tagName === 'h3') {
      // Process previous section
      if (currentSection.paragraphs.length > 0 || currentSection.header || currentSection.otherContent.length > 0) {
        processSection(currentSection);
        // Check if the tag is just a 'header' without content/image, instead of an actual 'thing to do' in the location
      }
      // Start new section
      currentSection = { header: node.outerHTML, paragraphs: [], otherContent: [], sectionIndex };
    } else if (node.tagName === 'P' || node.tagName == 'FIGURE') {
      currentSection.paragraphs.push(node.outerHTML);
    } else {
      // Other content, include as is
      currentSection.otherContent.push({ type: 'other', content: node.outerHTML });
    }
  });

  // Process the last section
  if (currentSection.paragraphs.length > 0 || currentSection.header || currentSection.otherContent.length > 0) {
    processSection(currentSection);
  }

  function processSection(section) {
    const { header, paragraphs, otherContent, sectionIndex: sIndex } = section;

    let charCount = 0;
    const minChars = 400;
    const paragraphsToShow = [];
    const paragraphsToHide = [];

    let hasImage = false;

    paragraphs.forEach((paragraph) => {
      const regex = /<img/g;

      const imgTag = paragraph.match(regex);

      if (imgTag) {
        hasImage = true;
        // Adding a place holder that will be replaced using the directive
        const smartImg = paragraph.replace(imgTag, '<smart-img');
        paragraph = smartImg;
      } else {
        const textLength = getTextLength(paragraph);
        charCount += textLength;
      }

      if (charCount <= minChars) {
        paragraphsToShow.push({ content: paragraph });
      } else {
        paragraphsToHide.push({ content: paragraph, hidden: true });
      }
    });

    const hasHiddenContent = paragraphsToHide.length > 0;

    sections.push({
      sectionIndex: sections.length === 0 ? sIndex : hasImage ? sIndex : null,
      header,
      paragraphs: [...paragraphsToShow, ...paragraphsToHide],
      hasHiddenContent,
      otherContent,
    });

    if (sections.length === 0 || hasImage) sectionIndex++;
  }
  return sections;
};

// This directive will replace all <smart-img> tags with the SmartImg component
// This is done because we can not create/inject a custom element with PascalCase using v.h or document.createElement.
const vProcesssmartimg = {
  updated: (el) => {
    // Dynamically load the SmartImg component
    const SmartImg = defineAsyncComponent(() => import('@/components/SmartImg.vue'));
    // Replace all <smart-img> tags with the SmartImg component
    el.querySelectorAll('smart-img').forEach((node) => {
      const props = {
        src: node.getAttribute('src'),
        alt: node.getAttribute('alt'),
        sizes: node.getAttribute('sizes'),
        srcset: node.getAttribute('srcset'),
        width: node.getAttribute('width'),
        height: node.getAttribute('height'),
      };

      // Rendering hte component
      const app = createApp(SmartImg, props);
      const placeholder = document.createElement('div'); // Placeholde for the mount
      app.mount(placeholder); // Mount the app to the placeholder

      node.replaceWith(placeholder); // Replace <smart-img> with the rendered component
    });
  },
};

const updateCurrentSectionIndex = () => {
  const sections = document.querySelectorAll('.td-post-content div[sectionIndex]');
  let currentIndex = 0;

  sections.forEach((section) => {
    const rect = section.getBoundingClientRect();
    if (rect.top <= window.innerHeight / 2 && rect.bottom >= 0) {
      currentIndex = parseInt(section.getAttribute('sectionIndex'), 10);
    }
  });

  currentSectionIndex.value = currentIndex;
};

const scrollToSection = (index) => {
  const section = document.querySelector(`.td-post-content div[sectionIndex="${index}"]`);
  if (section) {
    const offset = 80; // Ajust to specify how deep the croll should be from the top of the section
    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
    // Added delay to avoid conflict with the scroll function in /BlogNavigation/index.vue, line 148
    setTimeout(() => {
      window.scroll({ top: sectionTop - offset, behavior: 'smooth' });
    }, 200);
  }
};

</script>

<style scoped></style>
