<template>
  <!-- Content Preview -->
  <div
    v-show="!fullViewToC && !isMobile && currentSectionIndex <= sections.length && currentSectionIndex > 0"
    class="w-full flex flex-row justify-evenly items-center gap-8 fixed h-fit py-6 bg-white dark:bg-black z-30 md:bottom-0 md:left-0 md:px-56 md:py-3 border-t border-gray-200 dark:border-gray-700"
  >
    <!-- Buttons for navigation -->
    <div class="flex items-center gap-3">
      <!-- Left chevron -->
      <button
        @click="emit('update:currentSectionIndex', currentSectionIndex - 1)"
        :disabled="currentSectionIndex <= 1"
        class="disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <span class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700">
          <img
            src="/img/icons/chevron-left.svg"
            class="size-3 filter brightness-0 dark:filter dark:invert dark:invert-1"
            alt="arrow-right"
          />
        </span>
      </button>

      <!-- Right chevron -->
      <button
        @click="emit('update:currentSectionIndex', currentSectionIndex + 1)"
        :disabled="currentSectionIndex >= sections?.length"
        class="disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <span class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700">
          <img
            src="/img/icons/chevron-right.svg"
            class="size-3 filter brightness-0 dark:filter dark:invert dark:invert-1"
            alt="arrow-right"
          />
        </span>
      </button>
    </div>

    <!-- Current index indicator -->
    <p class="text-sm md:text-md font-bold">{{ `${currentSectionIndex} / ${sections?.length}` }}</p>

    <!-- Current section image and header -->
    <div class="flex w-2/5 items-center gap-4">
      <img :src="sections[currentSectionIndex-1]?.image" class="object-cover object-center rounded-md h-11 w-11" alt="placeholder" />
      <p class="text-sm font-semibold leading-tight line-clamp-2">{{ sections[currentSectionIndex-1]?.header }}</p>
    </div>

    <hr class="transform h-7 border" />

    <!-- Show table of content button -->
    <button @click="emit('update:fullViewToC', true)" class="ts-button justify-self-start">
      {{ t('Table of contents') }}
    </button>

    <!-- Share button -->
    <!-- <button class="justify-self-end flex items-center gap-2 hover:*:text-Tourscanner_blue-700">
      <img src="/img/icons/share_2.svg" class="size-4 filter dark:invert" alt="share" />
      <p class="font-bold hidden md:block text-sm">{{t("Share")}}</p>
    </button> -->
  </div>

  <!-- Button only shown in mobile -->
  <button
    v-if="!fullViewToC && isMobile && currentSectionIndex"
    @click="emit('update:fullViewToC', true)"
    class="w-3/4 py-2 fixed top-4 left-1/2 -translate-x-1/2 text-white bg-gradient-to-r from-ts_blue_to to-ts_blue_from flex justify-center items-center gap-3 text-center rounded-3xl z-30"
  >
    <p class="text-md">{{ t('Show table of contents') }}</p>
    <img
      src="/img/icons/chevron-down-select.svg"
      class="size-4 invert filter brightness-0 saturate-100 dark:invert-1"
      alt="arrow-left"
    />
  </button>

  <!-- Full Content View -->
  <div
    v-show="fullViewToC"
    class="h-screen w-full overflow-y-scroll fixed bottom-0 left-0 bg-white z-[60] dark:bg-[#121212] md:py-20 md:px-16"
  >
    <ul
      class="w-full h-full relative flex flex-col px-3 pt-20 items-center gap-6 md:grid md:grid-cols-5 md:grid-flow-row md:auto-rows-1fr md:pt-0"
    >
      <!-- Header for the table of contents -->
      <li
        class="bg-white max-w-screen fixed left-0 top-0 px-3 py-4 w-full flex items-center justify-between md:px-16 md:mt-0 md:col-span-5 dark:bg-[#121212]"
      >
        <h2 class="text-3xl">{{ t('Table of contents') }}:</h2>
        <button class="pr-4" @click="emit('update:fullViewToC', false)">
          <img src="/img/icons/close.svg" class="size-8 filter dark:invert" alt="arrow-left" />
        </button>
      </li>
      <!-- Grid/List of sections -->
      <li
        v-for="section in sections"
        :key="section.sectionIndex"
        @click="
          enableScroll();
          emit('update:currentSectionIndex', section.sectionIndex + 1);
          emit('update:fullViewToC', false);
        "
        class="h-full w-full flex items-start md:flex-col rounded-lg border-[1.5px] hover:border-Tourscanner_blue-600 cursor-pointer dark:border-gray-500 dark:hover:border-Tourscanner_blue-800"
      >
        <img
          :src="section.image"
          class="w-2/5 rounded-l-md object-fill object-center md:w-full md:object-cover md:h-full md:max-h-28 md:rounded-b-none md:rounded-t-md"
          alt="placeholder"
        />
        <p class="text-2xl px-5 font-bold  leading-7 md:text-md md:px-3 py-4">{{ section.header }}</p>
      </li>
      <!-- Empty list item to ensure the last item is not hidden behind the bottom navigation. Happens due to the nature of the layout (fixed + relative) -->
      <li class="p-4 md:col-span-5"></li>
    </ul>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref, watch } from 'vue';

const emit = defineEmits(['update:currentSectionIndex, update:fullViewToC']);

const { t, locale } = useI18n();

const { isMobile } = useDevice();

const props = defineProps({
  dataBlocks: {
    type: Array,
    default: () => [],
  },
  currentSectionIndex: {
    type: Number,
    default: 0,
  },
  fullViewToC: {
    type: Boolean,
    default: false,
  },
});

const scrollPosition = ref(0);
const sections = ref([]);

onMounted(() => {
  if (props.dataBlocks.length > 0) {
    sections.value = props.dataBlocks
      .filter((block) => block.__component === 'block.html')
      .flatMap((block) => parseSections(block.content));
  }
});

const disableScroll = () => {
  scrollPosition.value = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPosition.value}px`;
  document.body.style.width = '100%';
};

const enableScroll = () => {
  document.body.style.position = 'static';
  document.body.style.top = '0';
  document.body.style.width = '100%';
  window.scrollTo(0, scrollPosition.value);
};

watch(
  () => props.fullViewToC,
  (newVal) => {
    if (newVal) {
      disableScroll();
    } else {
      enableScroll();
    }
  },
);

const parseSections = (content) => {
  if (typeof window === 'undefined') return []; // Ensure it runs only on the client

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const elements = Array.from(doc.body.childNodes);

  const sections = [];
  let sectionIndex = 0;

  elements.forEach((node, index) => {
    const tagName = node.tagName?.toLowerCase();

    // Skip if the tag is just a 'header' without content, instead of an actual 'thing to do' in the location
    if (tagName == 'h2' && elements[index + 2]?.tagName?.toLowerCase() == 'h3') return;

    if (tagName === 'h3' || tagName === 'h2') {
      const nextNode = elements[index + 1]; // Get the next sibling node, bcz that is where the content of the section will be

      // If next sibling doesn't have an image, skip. This happens if the section is the end of the blog and just gives some tips instead of locations
      if (!nextNode?.nextSibling?.firstElementChild?.getAttribute('src') || nextNode?.nextSibling?.firstElementChild?.tagName?.toLowerCase() !== "img") return;

      // Start a new section with header
      const section = {
        sectionIndex,
        header: node.innerText || node.textContent || '', // Use plain text for header
        image: null, // Default to no image
      };

      // Check the next sibling to see if it's a div that contains an image

      section.image = nextNode?.nextSibling?.firstElementChild?.getAttribute('src');
      // Push the section with header and image (if available)
      sections.push(section);
      sectionIndex++;
    }
  });

  return sections;
};

watch(
  () => props.fullViewToC,
  (newVal) => {
    if (newVal) {
      window.addEventListener('keydown', (event) => event.key === 'Escape' && emit('update:fullViewToC', false));
    } else {
      window.removeEventListener('keydown', (event) => event.key === 'Escape' && emit('update:fullViewToC', false));
    }
  },
);
</script>
